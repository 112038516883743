import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('controller loaded')
  }
  change(event) {
    console.log("hello")
  }
}
